<template>
  <div class="company-contain">
    <div class="head-box">
      <div class="time-box">
        <div class="time-label">生成时段</div>
        <div class="day-type">
          <el-radio-group class="radio-group" v-model="params.data_type" size="small" @change="getListByTime">
            <!-- <el-radio-button label="hour">小时榜</el-radio-button> -->
            <el-radio-button label="day">日榜</el-radio-button>
            <el-radio-button label="week">周榜</el-radio-button>
            <el-radio-button label="month">月榜</el-radio-button>
          </el-radio-group>
        </div>
        <div class="time-filter-box">
          <!-- <el-select class="list-type-box" size="mini" v-model="params.date" placeholder="请选择" @change="getAllClass()">
            <el-option v-for="item in currentListType" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select> -->
          <el-date-picker
            v-model="params.date"
            :key="datePickerType"
            :type="datePickerType"
            :format="weekDisplayFormat"
            value-format="yyyy-MM-dd"
            :placeholder="`选择生成时段`"
            :picker-options="pickerOptions"
            :clearable="false"
            @change="handleDateChange"
            popper-class="date-no-light"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="sort-box">
        <div class="sort-label">排序方式</div>
        <div class="sort-type">
          <el-radio-group class="radio-group" v-model="params.data_sort" size="small" @change="getAllClass()">
            <el-radio-button label="1">播放最高</el-radio-button>
            <el-radio-button label="2">点赞最多</el-radio-button>
            <el-radio-button label="3">评论最多</el-radio-button>
            <el-radio-button label="4">热度最高</el-radio-button>
          </el-radio-group>
        </div>
      </div>
    </div>

    <div class="medio-contain">
      <el-scrollbar style="height: 100%" ref="scrollbar">
        <div class="medio-list" v-if="taskList.length > 0">
          <div class="medio-item" v-for="item in taskList" :key="item.id">
            <div class="medio-item_cover">
              <i class="medio-item_cover-rank" v-if="item.rank && item.rank < 100">{{ item.rank }}</i>
              <i class="medio-item_cover-rank" v-else>...</i>

              <img
                :src="item.item_cover"
                style="cursor: pointer"
                alt="图片加载失败"
                class="medio-item_cover-img"
                @error="handleImageError"
                @click="jumpToVideo(item.share_url)"
              />
            </div>

            <div class="medio-item_info">
              <div class="info-title" @click="jumpToVideo(item.share_url)">{{ item.author }} | {{ item.title }}</div>
              <!-- <div class="info-title">习近平同越共中央总书记、国家主席苏林会谈：你这次来华首站到访广东，很有意义。（来源：央视时政）</div> -->
              <div class="info-heat">
                <div class="info-heat_hot heat-detail">
                  <i class="info-heat_icon">
                    <svg
                      t="1724835033291"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="3570"
                      width="10"
                      height="10"
                    >
                      <path
                        d="M505.059556 1006.288593s697.837037 15.17037 273.066666-768.606815c0 0-45.511111 136.533333-70.769778 161.792 0 0-25.296593-267.984593-399.511703-399.473778 0 0 141.615407 273.066667-80.896 475.32563 0 0-25.296593-60.681481-60.681482-116.280889 0 0-353.962667 621.985185 338.792297 647.243852z"
                        fill="#999CB4"
                        p-id="3571"
                      ></path>
                    </svg>
                  </i>
                  <span>{{ formatCount(item.hot_value) }}</span>
                </div>
                <div class="heat-detail">
                  <i class="info-heat_icon">
                    <svg
                      t="1724835239545"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="5709"
                      width="16"
                      height="16"
                      style="transform: translateX(1px)"
                    >
                      <path
                        d="M122.112664 953.98846l816.371172-407.954319c0.723477-0.342808 1.411139-0.689708 2.099824-1.051959l1.107217-0.550539 0-0.077771c11.644201-6.757914 19.488867-19.377326 19.488867-33.827433 0-14.452153-7.844665-27.070542-19.488867-33.828456l0-0.228197L120.241037 65.955154c-1.14508-0.649799-2.310625-1.24127-3.51301-1.77646l-2.481518-1.240247-0.230244 0.170892c-4.047176-1.431605-8.41874-2.196015-12.96222-2.196015-21.589714 0-39.096437 17.506722-39.096437 39.096437 0 0.192382 0 0.382717 0 0.593518l0 821.276902 0.01842 0c0.438998 21.210068 17.754363 38.255279 39.078017 38.255279C108.80559 960.13546 116.040365 957.887256 122.112664 953.98846z"
                        fill="#999CB4"
                        p-id="5710"
                      ></path>
                    </svg>
                  </i>
                  <span>{{ formatCount(item.play_count) }}</span>
                </div>
                <div class="heat-detail">
                  <i class="info-heat_icon">
                    <svg
                      t="1724835259376"
                      class="icon"
                      viewBox="0 0 1025 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="6894"
                      width="16"
                      height="16"
                    >
                      <path
                        d="M1024.549 360.609c0-170.492-133.815-309.265-298.055-309.265-81.129 0-157.91 34.998-213.344 94.701-55.509-59.702-132.367-94.701-213.344-94.701C135.49 51.344 1.751 190.041 1.751 360.609c0 5.719 0.534 10.827 0.991 15.021-0.076 1.373-0.152 2.745-0.152 4.194 0 30.193 7.319 63.361 21.73 98.59 0.458 1.295 0.915 2.516 1.449 3.657 90.812 217.844 440.412 468.474 455.279 479.985 9.227 7.092 20.205 10.6 31.263 10.6 11.209 0 22.266-3.659 31.566-10.903 12.733-9.911 310.941-224.551 429.279-427.603 4.498-6.861 7.854-13.494 10.828-19.215 0.914-1.829 1.753-3.658 2.744-5.413l0.382-0.839c0.382-0.686 0.839-1.449 1.296-2.059 7.091-13.802 12.732-26.611 17.232-39.116 12.274-32.177 18.3-60.847 18.3-87.61 0-2.058-0.077-3.888-0.229-5.414C1024.093 370.979 1024.549 366.251 1024.549 360.609z"
                        fill="#999CB4"
                        p-id="6895"
                      ></path>
                    </svg>
                  </i>
                  <span>{{ formatCount(item.digg_count) }}</span>
                </div>
                <div class="heat-detail">
                  <i class="info-heat_icon">
                    <svg
                      t="1724835320725"
                      class="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="15116"
                      width="16"
                      height="16"
                    >
                      <path
                        d="M512 0a512 512 0 0 1 389.76 844.032l108.992 127.168a32 32 0 0 1-24.32 52.8H512A512 512 0 1 1 512 0zM224 448a80 80 0 1 0 0 160 80 80 0 0 0 0-160z m256 0a80 80 0 1 0 0 160 80 80 0 0 0 0-160z m256 0a80 80 0 1 0 0 160 80 80 0 0 0 0-160z"
                        fill="#999CB4"
                        p-id="15117"
                      ></path>
                    </svg>
                  </i>
                  <span>{{ formatCount(item.comment_count) }}</span>
                </div>
              </div>
              <div class="info-hot">
                热词： <span>{{ item.hot_words && item.hot_words.split(",").join(" · ") }}</span>
                <!-- 热词： <span>{{ "主席,苏林,国家,书记,来源,会谈,习近平,越共中央,总书记,这次,来华,首站,到访,广东,意义,央视,时政".split(",").join(" · ") }}</span> -->
              </div>
            </div>
          </div>
        </div>

        <div class="medio-load">
          <span v-if="!loadMore" style="cursor: auto">没有更多作品</span>
          <span v-else @click="loadMoreData">点击加载更多</span>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import elTableInfiniteScroll from "el-table-infinite-scroll";

let _this;
export default {
  name: "HotVideo",
  directives: {
    "el-table-infinite-scroll": elTableInfiniteScroll,
  },
  data() {
    return {
      typeList: [], //分类列表
      taskList: [], //任务列表
      params: {
        data_type: "day",
        data_sort: "1",
        date: "",
        // order_by: "synthesize",
        // star_category: "",
        page: 0,
        limit: 50,
      },
      flag: true,
      showScrollLoading: false,
      // timeFilterOptions: HOTVIDEO.timeFilterOptions,
      // loading: true,
      loadMore: false, // 是否加载更多数据
      pickerOptions: {
        firstDayOfWeek: 1, // 设置周一为一周的第一天
        disabledDate: (time) => {
          // 禁用未来的日期
          const today = new Date();
          if (this.params.data_type == "day") {
            today.setDate(today.getDate() - 1);
          } else if (this.params.data_type == "week") {
            const dayOffset = today.getDay() === 0 ? 6 : today.getDay() - 1;
            today.setDate(today.getDate() - dayOffset - 1);
          } else if (this.params.data_type == "month") {
            today.setMonth(today.getMonth() - 1);
          }

          return time.getTime() > today;
        },
      },
    };
  },
  mounted() {
    _this = this;
    // this.getAllClass();
  },
  computed: {
    /**当前的榜单 */
    // currentListType() {
    //   let { data_type } = this.params;
    //   return this.timeFilterOptions[data_type];
    // },
    datePickerType() {
      return this.params.data_type == "day" ? "date" : this.params.data_type;
    },
    // 根据周的开始和结束日期格式化显示
    weekDisplayFormat() {
      if (this.params.data_type == "week") {
        if (!this.params.date) return "yyyy-MM-dd ~ yyyy-MM-dd";
        const startDate = new Date(this.params.date); // 选择的周的第一天
        const startOfWeek = new Date(startDate);
        const endOfWeek = new Date(startDate);

        // 调整为周一开始
        const dayOfWeek = startDate.getDay();
        const dayOffset = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
        startOfWeek.setDate(startDate.getDate() - dayOffset); // 计算周一
        endOfWeek.setDate(startOfWeek.getDate() + 6); // 计算周日

        // 返回自定义的周范围格式
        return `${this.formatDate(startOfWeek)} ~ ${this.formatDate(endOfWeek)}`;
      } else {
        return this.params.data_type == "month" ? "yyyy-MM" : "yyyy-MM-dd";
      }
    },
  },
  filters: {},
  watch: {
    "params.data_type": {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          const today = new Date();
          if (this.params.data_type === "day") {
            // 如果是 "day" 类型，选择当天日期
            today.setDate(today.getDate() - 1);
            this.params.date = this.formatDate(today);
          } else if (this.params.data_type === "week") {
            // 如果是 "week" 类型，选择本周的范围
            const startOfWeek = new Date(today);
            const dayOfWeek = today.getDay();
            const dayOffset = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
            startOfWeek.setDate(today.getDate() - dayOffset - 7); // 上周周一
            this.params.date = `${this.formatDate(startOfWeek)}`;
          } else if (this.params.data_type === "month") {
            // 如果是 "month" 类型，选择本月的范围
            const endOfMonth = new Date(today.getFullYear(), today.getMonth(), 0);
            this.params.date = `${this.formatDate(endOfMonth)}`;
          }
        }

        this.getAllClass();
      },
      immediate: true,
    },
  },
  methods: {
    getListByTime() {
      this.getAllClass();
    },

    handleDateChange(value) {
      this.getAllClass();
    },

    // 格式化日期为 yyyy-MM-dd 格式
    formatDate(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },

    // 根据日期生成时段不同格式化时间
    formatParams() {
      const params = JSON.parse(JSON.stringify(this.params));
      if (params.data_type == "week") {
        const startDate = new Date(params.date);
        const dayOffset = startDate.getDay() === 0 ? 6 : startDate.getDay() - 1;
        startDate.setDate(startDate.getDate() - dayOffset);
        const endDay = new Date(startDate);
        endDay.setDate(endDay.getDate() + 6);
        params.date = `${this.formatDate(startDate)},${this.formatDate(endDay)}`;
      } else {
        params.date = params.data_type == "day" ? params.date : params.date.slice(0, -3);
      }

      return params;
    },

    // 获取全部视频列表
    getAllClass(isAppend = false) {
      if (this.flag) {
        this.flag = false;

        if (!isAppend && this.$refs.scrollbar) {
          const scrollbarWrap = this.$refs.scrollbar.wrap;
          if (scrollbarWrap) {
            scrollbarWrap.scrollTop = 0;
          }
        }

        // params处理
        this.params.page = isAppend ? this.params.page + 1 : 1;

        const requestParams = this.formatParams();

        this.$httpStudent.axiosGetBy(
          this.$api.billboardHotVideo,
          requestParams,
          (res) => {
            if (res.code === 200) {
              this.taskList = isAppend ? this.taskList.concat(res.data.list) : res.data.list;

              if (res.data.total === 0 || this.taskList.length >= res.data.total) this.loadMore = false;
              else this.loadMore = true;
            } else {
              this.$message({
                type: "error",
                message: res.message,
                duration: 1000,
              });
            }
            this.flag = true;
          },
          (error) => {
            this.flag = true;
          }
        );
      }
    },

    // 格式化数量
    formatCount(value) {
      const count = parseFloat(value);
      if (isNaN(count)) return 0;

      if (count < 10000) return count.toString();
      else return (count / 10000).toFixed(1) + "万";
    },

    // 加载更多数据
    loadMoreData() {
      this.getAllClass(true);
    },

    //跳到详情页
    changeToDetail(row) {
      this.$router.push({
        path: "/student/hot/videoDetail",
        query: {
          aweme_id: row.aweme_id,
          author_id: row.author_id,
        },
      });
    },
    //跳到粉丝详情
    jumpToFansData(row) {
      this.$router.push({
        path: "/student/hot/videoDetail",
        query: {
          aweme_id: row.aweme_id,
          author_id: row.author_id,
        },
      });
    },
    //打开视频
    jumpToVideo(url) {
      window.open(url);
    },

    handleImageError(event) {
      event.target.src = require("@/assets/img/image/noimage.png");
    },
  },
};
</script>

<style scoped lang="scss">
.el-int {
  width: 250px;
  margin-left: 20px;
}

.activeClass {
  background: #2338e6;
  color: #ffffff;
}

.company-contain {
  display: flex;
  flex-direction: column;
  background: #fff;

  .head-box {
    padding: 24px;
    background: #fff;

    .time-box {
      display: flex;
      align-items: center;

      .day-type {
        margin-right: 6px;
      }
    }

    .sort-box {
      margin-top: 16px;
      display: flex;
      align-items: center;
    }

    .time-box,
    .sort-box {
      .time-label,
      .sort-label {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #252632;
        line-height: 16px;
      }

      gap: 10px;

      .radio-group {
        padding: 4px;
        border-radius: 4px;
        background-color: #f2f2f4;

        ::v-deep .el-radio-button__orig-radio + .el-radio-button__inner {
          background-color: transparent;
          color: rgba(37, 38, 50, 0.6);
          font-size: 14px;
          font-weight: 400;
          border-color: transparent;
          box-shadow: none;
          border-radius: 4px;
          padding: 2px 24px;
        }

        ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
          background-color: #ffffff;
          color: #594fee;
        }

        ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner:hover {
          color: #594fee;
        }

        ::v-deep .el-radio-button__inner:hover {
          color: #2338e6;
        }
      }

      .time-filter-box {
        ::v-deep .el-input__inner {
          background-color: #f2f2f4;
          color: #252632;
          border: none;
          height: 28px;
          line-height: 28px;
          font-size: 14px;
        }

        ::v-deep .el-input__icon {
          line-height: 28px;
        }
      }
    }
  }

  .medio-contain {
    height: calc(100vh - 230px);
    // background-color: #a3a3c4;
    box-sizing: border-box;
    padding: 0 24px;

    ::v-deep .el-scrollbar .el-scrollbar__wrap {
      overflow-x: hidden;
    }

    .medio-list {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
      box-sizing: border-box;

      .medio-item {
        // width: 47%;
        padding: 16px 20px;
        background-color: #f7f9fb;
        border-radius: 8px;
        display: flex;
        gap: 10px;
        align-items: center;
        overflow: hidden;

        &_cover {
          width: 45px;
          height: 60px;
          border-radius: 4px;
          overflow: hidden;
          position: relative;

          &-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          &-rank {
            position: absolute;
            width: 14px;
            height: 14px;
            z-index: 10;
            background: #929399;
            color: #fff;
            border-right: 1px solid #fff;
            border-bottom: 1px solid #fff;
            font-size: 10px;
            line-height: 12px;
            font-style: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px 0px 8px 0px;
          }
        }

        &_info {
          width: calc(100% - 60px);
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          & > div {
            font-weight: 400;
            font-style: normal;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .info-title {
            font-size: 14px;
            line-height: 16px;
            color: #252632;
            cursor: pointer;

            &:hover {
              color: #594fee;
            }
          }

          .info-heat {
            display: flex;
            align-items: center;
            gap: 20px;

            span {
              font-size: 12px;
              color: rgba(28, 31, 35, 0.6);
              line-height: 14px;
              gap: 20px;
            }

            .heat-detail {
              display: flex;
              align-items: center;
              gap: 5px;

              i {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                background-color: #e8eaef;
                border-radius: 50%;
                position: relative;

                svg.icon {
                  width: 12px;
                  height: 12px;
                }
              }
            }
          }

          .info-hot {
            font-size: 12px;
            line-height: 14px;
            color: rgba(28, 31, 35, 0.6);
          }
        }

        &:nth-child(1) {
          .info-heat_hot i {
            background-color: #f9dde0 !important;
          }

          .info-heat_hot svg path {
            fill: #ff2c55;
          }

          .medio-item_cover-rank {
            background-color: #ff2c55;
          }
        }

        &:nth-child(2) {
          .info-heat_hot i {
            background-color: #f9dfda !important;
          }

          .info-heat_hot svg path {
            fill: #ff7352;
          }

          .medio-item_cover-rank {
            background-color: #ff851d;
          }
        }

        &:nth-child(3) {
          .info-heat_hot i {
            background-color: #f8e7d1 !important;
          }

          .info-heat_hot svg path {
            fill: #ffa132;
          }

          .medio-item_cover-rank {
            background-color: #face15;
          }
        }
      }
    }

    .medio-load {
      display: flex;
      justify-content: center;
      padding: 25px 0;

      span {
        font-weight: 400;
        font-size: 16px;
        color: #888888;
        line-height: 19px;
        cursor: pointer;
      }
    }
  }
}
</style>
